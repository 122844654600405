import React, { PureComponent, ReactNode } from 'react'

import Layout from '../components/layout'
import Section, {Header, SubHeader, Divider} from '../components/section/section'
import ContactInfo from '../components/contactInfo'
import { WindowLocation } from '@reach/router'

import theme from '../theme'

interface RatesPageProps {
  location: WindowLocation,
  children: ReactNode[]
}

export default class Rates extends PureComponent<RatesPageProps, {}> {
  render() {
    return(
      <Layout location={this.props.location}>
        <Section width="100%" margin="0" flexDirection="column" background="whitesmoke">
          <Header>Rates</Header>
          <SubHeader>
            Contact Strange Land Recording Studios for the latest rates
          </SubHeader>
          <Divider themeColor={theme.palette.primary.main}/>
          <ContactInfo />
        </Section>
      </Layout>
    )
  }
}